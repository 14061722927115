import React, { useState, useEffect } from "react";
import "../styles/blog.css";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { StaticImage } from "gatsby-plugin-image";
import { posts } from "../constants/blog-posts";

const Blog = () => {
  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 961);

  useEffect(() => {
    if (!isBrowser) return false;

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return (
    <Layout
      title="Joeli Toscano | Blog"
      description="Joeli Toscano - Journalist. A collection of Joeli Toscano's blog posts, writings, and other works. Joeli Toscano Blog and Joeli Toscano's blog posts."
    >
      <div className="blog-container">
        <div className="blog-header">
          <StaticImage
            src="../images/gallery/chicago-lake.jpg"
            className="blog-header-img"
            alt="Chicago beach blog header"
            placeholder="blurred"
            loading="eager"
          />
        </div>
        <div className="blog-thumbs">
          <Box>
            <ImageList
              cols={isBrowser ? (width < 960 ? 1 : 3) : 2}
              gap={isBrowser ? (width < 960 ? 20 : 100) : 20}
            >
              {posts.map((post) => (
                <Link key={post.id} to={post.link}>
                  <ImageListItem>
                    {post.thumb}
                    <ImageListItemBar title={post.title} />
                  </ImageListItem>
                </Link>
              ))}
            </ImageList>
          </Box>
        </div>
      </div>
    </Layout>
  );
};

export default Blog;
